<template>
  <div>
    <div :class="system.isPhone ? 'wap_apply_form_all' : 'apply_form_all'">
      <el-form
        v-if="isShow"
        ref="applyAgentForm"
        :label-position="system.isPhone ? 'top' : 'right'"
        label-width="auto"
        :model="form"
        :rules="rules"
        size="mini"
      >
        <!-- <div class="type_choose">
          <div
            class="item"
            :class="activeType === 1 ? 'active' : ''"
            @click="changeType(1)"
          >
            {{ $t("AaAbordTop.azBtn1") }}
          </div>
          <div
            class="item"
            :class="activeType === 2 ? 'active' : ''"
            @click="changeType(2)"
          >
            {{ $t("AaAbordTop.azBtn2") }}
          </div>
        </div> -->
        <form-bar :whoApply="whoApply" name=".firstName"></form-bar>

        <div class="form_css">
          <!-- 公司名称 -->
          <text-input
            prop="companyName"
            class="text_line"
            v-model="form.companyName"
            :label="$t('applyAgent.' + whoApply + '.companyName')"
          ></text-input>

          <!-- 境内/外公司名称 -->
          <text-input
            prop="abn"
            class="text_line"
            v-model="form.abn"
            :label="$t('applyAgent.' + whoApply + '.abn')"
          ></text-input>

          <!-- 开户银行 1-->
          <text-input
            prop="bankName"
            class="text_line"
            v-model="form.bankName"
            :label="$t('applyAgent.' + whoApply + '.bankName')"
          ></text-input>

          <!-- 分行代码 1-->
          <text-input
            prop="bankCode"
            class="text_line"
            v-model="form.bankCode"
            :label="$t('applyAgent.' + whoApply + '.bankCode')"
          ></text-input>

          <!-- 银行账号 1-->
          <text-input
            v-if="whoApply != 'personalApply'"
            prop="companyName"
            class="text_line"
            v-model="form.cardNumber"
            :label="$t('applyAgent.' + whoApply + '.cardNumber')"
          ></text-input>
        </div>

        <form-bar :whoApply="whoApply" name=".secondName"></form-bar>

        <div class="form_css">
          <!-- 公司地址 -->
          <text-input
            prop="companyAddress"
            class="text_line"
            v-model="form.companyAddress"
            :maxlength="100"
            :label="$t('applyAgent.' + whoApply + '.companyAddress')"
          ></text-input>

          <!-- 城市 -->
          <text-input
            prop="city"
            class="text_line"
            v-model="form.city"
            :label="$t('applyAgent.' + whoApply + '.city')"
          ></text-input>

          <!-- 邮编 -->
          <text-input
            prop="postcode"
            class="text_line"
            v-model="form.postcode"
            :label="$t('applyAgent.' + whoApply + '.postcode')"
          ></text-input>
        </div>
        <form-bar :whoApply="whoApply" name=".thirdName"></form-bar>

        <div class="form_css">
          <!-- 身份证姓名 -->
          <text-input
            prop="idcardName"
            class="text_line"
            v-model="form.idcardName"
            :label="$t('applyAgent.' + whoApply + '.idcardName')"
          ></text-input>

          <!-- 电话 -->
          <text-input
            prop="phone"
            class="text_line"
            v-model="form.phone"
            :label="$t('applyAgent.' + whoApply + '.phone')"
          ></text-input>

          <!-- 邮箱填写 -->
          <text-input
            prop="email"
            class="text_line"
            v-model="form.email"
            :label="$t('applyAgent.' + whoApply + '.email')"
          ></text-input>
        </div>
        <el-form-item>
          <el-button
            class="next_but "
            type="primary"
            @click="submitForm('applyAgentForm')"
            >{{ $t("applyAgent.errMsg.nextStep") }}</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import textInput from "@/views/formComponents/textInput";
import formBar from "../../applyAgent/components/formBar";
import { verifyEmail } from "@/utils/pattern.js";

export default {
  components: {
    formBar,
    textInput
  },
  data() {
    // var phone = (rule, value, callback) => {
    //   if (!verifyPhone(value, `+${this.ruleForm.areaCode}`)) {
    //     this.canSendCode = "false";
    //     callback(new Error(this.$t("nameInputPhone")));
    //   } else {
    //     this.canSendCode = "true";
    //     callback();
    //   }
    // };
    var email = (rule, value, callback) => {
      if (!verifyEmail(value)) {
        callback(new Error(this.$t("applyAgent.errMsg.emailnotallow")));
      } else {
        callback();
      }
    };
    var notNull = (rule, value, callback) => {
      if (value == "") {
        callback(new Error(this.$t("applyAgent.errMsg.notNull")));
      } else {
        callback();
      }
    };
    // var img = (rule, value, callback) => {
    //   if (this.form[rule.field].indexOf("http") < 0) {
    //     callback(new Error(this.$t("applyAgent.errMsg.notNull")));
    //   } else {
    //     callback();
    //   }
    // };

    return {
      isShow: true,
      activeType: Number(this.$route.query.type) || 1,
      whoApply: "applyOversea",
      labelPosition: "right",
      form: {
        companyName: "",
        abn: "",
        companyAddress: "",
        city: "",
        postcode: "",
        idcardName: "",
        phone: "",
        email: "",
        // physicalAddress: "实体店地址",
        // physicalImgUrl: "实体店照片",
        businessLine: 3, //1:线上  2：线下
        kind: "AustraliaCompany", //个人Personal;中国企业ChinaCompany; 澳洲企业AustraliaCompany
        // memo: "描述"
        bankCode: "",
        cardNumber: "",
        bankName: ""
      },
      canSendCode: "false",
      rules: {
        phone: [{ required: true, validator: notNull }],
        bankCode: [{ required: true, validator: notNull, trigger: "blur" }],
        bankName: [{ required: true, validator: notNull, trigger: "blur" }],
        cardNumber: [{ required: true, validator: notNull, trigger: "blur" }],
        email: [{ required: true, validator: email, trigger: "blur" }],
        companyName: [{ required: true, validator: notNull, trigger: "blur" }],
        idcardName: [{ required: true, validator: notNull, trigger: "blur" }],
        idcardNumber: [{ required: true, validator: notNull, trigger: "blur" }],
        abn: [{ required: true, validator: notNull }],
        companyAddress: [{ required: true, validator: notNull }],
        city: [{ required: true, validator: notNull }],
        postcode: [{ required: true, validator: notNull }]
      }
    };
  },
  methods: {
    // 切换类型
    changeType(type) {
      if (type === this.activeType) {
        return;
      }
      this.activeType = type;
      this.isShow = false;

      Object.keys(this.form).map(key => {
        this.form[key] = "";
      });
      // if(type === 1) {
      //   this.form.businessLine = 3;
      // } else {
      //   this.form.businessLine = 4;
      // }
      this.$nextTick(() => {
        this.isShow = true;
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.tijiao();
        } else {
          return false;
        }
      });
    },
    uplodeChange(value) {
      console.log(this.$refs);
      this.form = { ...this.form, ...value };
      this.$refs["applyAgentForm"].validateField(Object.keys(value)[0]);
    },

    tijiao() {
      if (this.activeType === 1) {
        this.form.businessLine = 3;
      } else {
        this.form.businessLine = 4;
      }
      let data = { ...this.form };
      //进行提交
      this.$request("/api/front/userInfoCheck/addUserInfoCheck", {
        method: "POST",
        data
      })
        .then(res => {
          if (res.code === 1) {
            this.$router.replace({
              path: "applyAfter",
              query: { status: "daishenhe" }
            });
            this.$message({
              message: "提交成功，等待审核",
              type: "none",
              customClass: "zidingyiel_message"
            });
          }
        })
        .catch(() => {});
    }
  }
};
</script>
<style lang="less" scoped>
.type_choose {
  display: flex;
  margin-bottom: 30px;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  .item {
    border: 1px solid #aaa;
    border-radius: 4px;
    padding: 6px 18px;
    margin-right: 20px;
    cursor: pointer;
    &:last-child {
      margin-right: 0;
    }
    &.active {
      background: #1977fb;
      color: #fff;
      border: 0;
    }
  }
}
.zanwei {
  height: 60px;
}
.next_but {
  width: 272px;
  height: 53px;
  border-radius: 67px;
  margin-top: 60px;
}
.apply_form_all {
  width: 100%;
  .text_line {
    // padding-top: 18px;
    // padding-bottom: 18px;
  }
  /deep/.form_css {
    max-width: 660px;
    margin: auto;
    margin-bottom: 60px;
    margin-top: 36px;
    .el-form-item--mini {
      margin-bottom: 36px;
    }
    .el-textarea__inner.el-textarea__inner {
      height: 100px;
    }
  }
}
.wap_apply_form_all {
  .zanwei {
    height: calc(100vw * 1.2 / 37.5);
  }
  /deep/.el-form-item__content {
    text-align: center;
  }
  .type_choose {
    margin-bottom: calc(100vw * 3 / 37.5);
    font-size: calc(100vw * 1.2 / 37.5);
    .item {
      border: calc(100vw * 0.1 / 37.5) solid #aaa;
      border-radius: calc(100vw * 0.4 / 37.5);
      padding: calc(100vw * 0.6 / 37.5) calc(100vw * 1.8 / 37.5);
      margin-right: calc(100vw * 1 / 37.5);
      cursor: pointer;
      // &.first-child{
      //   margin-right: 0;
      // }
      &:last-child {
        margin-right: 0 !important;
      }
    }
  }
  .next_but {
    width: calc(100vw * 32.5 / 37.5);
    height: calc(100vw * 4.2 / 37.5);
    opacity: 1;
    background: #1977fb;
    border-radius: 6px;
    margin: auto;
  }
  /deep/.form_css {
    width: auto;
    margin-right: 20px;
    margin-left: 20px;

    .el-textarea__inner {
      height: calc(100vw * 12 / 37.5);
      border: none;
      border-bottom: 1px solid #dcdfe6;
      border-radius: 0;
    }
    .el-form-item__label {
      font-size: calc(100vw * 1.2 / 37.5);
      padding: 0;
      margin-top: calc(100vw * 2.4 / 37.5);
    }
    .el-input__inner {
      font-size: calc(100vw * 1.2 / 37.5);
      height: auto;
      line-height: calc(100vw * 3.2 / 37.5);
      border-radius: 0px;
      border: 1px none #dcdfe6;
      border-bottom: 1px solid #dcdfe6;
    }
    .uplode,
    .uplode_icon {
      width: calc(100vw * 15 / 37.5);
      height: calc(100vw * 10 / 37.5);
    }
  }
}
</style>
